.transaction-filter-box {
  border-color: #404040AB;
  border-width: 1px;
  border-radius: 0.5rem;

  .title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  }
}

.show-search-transactions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.filter-and-content-separator {
  margin: 2rem;
  border-color: #404040AB;
  border-width: 2px;
  border-radius: 1rem;
}

.transaction-card {
  border-color: #404040AB;
  border-width: 1px;
  border-radius: 0.5rem;
}
